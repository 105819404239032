<template>

  <div class=" content_container">

    <div class="box">

      <el-breadcrumb separator-class="el-icon-arrow-right ">

        <el-breadcrumb-item>
          <span @click="$router.push('/member-order')">我的订单 </span>
        </el-breadcrumb-item>
        <el-breadcrumb-item>订单详情</el-breadcrumb-item>
      </el-breadcrumb>

    </div>
    <div class="box">
      <h1>订单流程</h1>
      <div v-if='orderDetail.order_status_id==19' style="margin-bottom:20px">
        <el-alert title=" 已取消订单，交易关闭。" type="warning" :closable="false">
        </el-alert>
        <p style="color:#E6A23C;padding-left:30px;margin-top:10px">{{orderDetail.isvoid_time}}</p>
      </div>
      <div v-if="(orderDetail.order_status_id == 11||orderDetail.order_status_id == 18)&&!((orderDetail.getrefund || {}).refund_state == 1 ||
                        (orderDetail.getrefund || {}).seller_state == 1)" style="margin-bottom:20px">
        <el-alert title="已退款" type="warning" :closable="false">
          <span v-if='orderDetail.isstrange==2'>
            {{orderDetail.getrefund.buyer_message}}

          </span>
          <span v-else>
            {{orderDetail.getrefund.reason_info}}

          </span>

        </el-alert>

        <p style="color:#E6A23C;padding-left:30px;margin-top:10px">{{orderDetail.isvoid_time}}</p>
      </div>
      <el-steps :active="info">
        <el-step title="拍下商品" :description="orderDetail.date_added "></el-step>
        <el-step title="已付款" :description="orderDetail.pay_date "></el-step>

        <el-step title="  卖家发货" :description="orderDetail.shipping_date"></el-step>
        <el-step title="   确认收货" :description="orderDetail.shou_date"></el-step>
        <el-step title="   评价" :description=" orderDetail.comment_date "></el-step>

      </el-steps>
      <div class="zt">
        <div>
          <p @click="refund((orderDetail.getrefund||{}).zorder_id)" class='hover'>

            <span v-if="(orderDetail.getrefund || {}).seller_state == 1&& (orderDetail.getrefund || {}).refund_type==1&&(orderDetail.getrefund || {}).refund_state != 4 ">
              已申请退款中
            </span>
            <span v-if=' ((orderDetail.getrefund || {}).refund_state == 5 ||
            (orderDetail.getrefund || {}).seller_state == 3) &&
          (orderDetail.getrefund || {}).refund_type == 1'>
              已申请退款，商家拒绝退款
            </span>
            <span v-if="((orderDetail.getrefund || {}).seller_state == 1&& (orderDetail.getrefund || {}).refund_type==2 )">
              已申请退货中
            </span>
            <span
              v-if="(orderDetail.getrefund || {}).seller_state == 3&&(orderDetail.getrefund || {}).refund_type==2&&(orderDetail.getrefund || {}).order_status_id != 5&&(orderDetail.getrefund || {}).order_status_id != 6">
              已申请退货中,商家拒绝退货退款
            </span>
            <span v-if='(orderDetail.getrefund || {}).express_id&&(orderDetail.getrefund || {}).seller_state==5'>
              您已退货,待商家签收
            </span>
            <span v-if='(orderDetail.getrefund || {}).refund_state == 4'>
              已退货,商家已退款
            </span>
            <span v-if="
                          ((orderDetail.getrefund || {}).seller_state == 5||(orderDetail.getrefund || {}).refund_state == 6)&&(orderDetail.getrefund || {}).refund_type==2&&!(orderDetail.getrefund || {}).express_id
                          ">
              商家已同意退货，请及时退货
            </span>

          <span v-if="(orderDetail.getrefund || {}).seller_state == 7&& (orderDetail.getrefund || {}).refund_type==2&&!(orderDetail.order_status_id == 5||orderDetail.order_status_id == 6)">
              您已经退货，商家拒绝退款
            </span>

          </p>

          <template v-if='orderDetail.get_complain'>
            <span v-if='orderDetail.get_complain.admin_status==0'   >
              投诉中
            </span>
            <span v-else @click="toComplain(orderDetail.get_complain.id)" style="color:red">
              投诉已完成
            </span>
          </template>
        </div>

        <ul class="btn">
          <li v-if="orderDetail.shipping_code" @click="logistics(orderDetail)">
            查看物流

          <li v-if='orderDetail.order_status_id==1' @click="cancel_box=true">
            取消订单
          </li>
          <li v-if='orderDetail.order_status_id==1' @click="send(orderDetail)">
            付款
          </li>
          <li @click="refund(orderDetail.order_id)" v-if=" ( !orderDetail.getrefund && orderDetail.order_status_id == 3) &&!( (orderDetail.getrefund || {}).refund_state == 1 ||
                        (orderDetail.getrefund || {}).seller_state == 1)
                          ">

            退货退款
          </li>
          <li v-if='orderDetail.order_status_id==19' @click="del_box=true">删除订单</li>
          <li v-if='orderDetail.order_status_id==19' @click="toBuy(orderDetail.order_id)">再次购买</li>
          <li v-if='orderDetail.order_status_id==3' @click="
       confirm()
        ">
            确认收货
          </li>

          <li v-if='   ( !orderDetail.getrefund && orderDetail.order_status_id == 13) &&!( (orderDetail.getrefund || {}).refund_state == 1 ||
                        (orderDetail.getrefund || {}).seller_state == 1)' @click="$router.push({path:'/member-order-refund',  query: { id: $route.query.order_id, num: 1, str: 'nogoods' }})">
            退款
          </li>

          <li @click="evaluate(orderDetail.order_id,1)" v-if=' orderDetail.order_status_id==5'>
            评价
          </li>

          <li @click="toAddComplain(orderDetail.order_id)
                       
                        " v-if="orderDetail.order_status_id != 1&&!orderDetail.get_complain&&orderDetail.order_status_id != 7&&orderDetail.order_status_id != 19">

            投诉
          </li>

        </ul>
      </div>

    </div>
    <div class="box">
      <el-row :gutter="20">
        <el-col :span="12">
          <h1>物流信息</h1>
          <div class="emty" v-if='!orderDetail.shipping_name'>
            <i class="el-icon-warning-outline"></i>
            <p>暂无内容</p>
          </div>
          <table v-else>
            <tr>
              <td style='width:20%'>快递名称</td>
              <td> {{orderDetail.shipping_name}}</td>
            </tr>
            <tr>
              <td>快递单号</td>
              <td>{{orderDetail.shipping_code}}} </td>
            </tr>
            <tr>
              <td>物流信息</td>
              <td>
                <el-button type="primary" size="mini" @click="wuliu">查看物流</el-button>
              </td>
            </tr>
          </table>
        </el-col>
        <el-col :span="12" v-if='type==1'>
          <h1>发票信息</h1>
          <div class="emty" v-if='!orderDetail.zorder.data[0].get_invoice'>
            <i class="el-icon-warning-outline"></i>
            <p>暂无内容</p>
          </div>
          <table v-else>

            <tr>
              <td style="width:100px">抬头信息</td>
              <td>{{orderDetail.zorder.data[0].get_invoice.invoice_title}}</td>
            </tr>
            <tr>

              <td>状态</td>
              <td> <span
                  v-if="orderDetail.zorder.data[0].get_invoice.invoice_status==1&& orderDetail.zorder.data[0].get_invoice.is_invoice_status==0&&orderDetail.zorder.data[0].get_invoice.isstrange!=2"
                  style="font-size:12px;color:red">(待开发票)</span>

                <span v-else-if=" orderDetail.zorder.data[0].get_invoice.invoice_status==1&& orderDetail.zorder.data[0].get_invoice.is_invoice_status==1"
                  style="font-size:12px;color:red;  cursor: pointer;margin-left:10px" @click="dialogFormVisible3=true"> 查看已开发票</span>
                <span v-else>无</span>
              </td>
            </tr>
          </table>
        </el-col>
        <el-col :span="12" v-else>
          <h1>发票信息</h1>
          <div class="emty" v-if='!orderDetail.get_invoice'>
            <i class="el-icon-warning-outline"></i>
            <p>暂无内容</p>
          </div>
          <table v-else>

            <tr>
              <td style="width:100px">抬头信息</td>
              <td>{{orderDetail.get_invoice.invoice_title}}</td>
            </tr>
            <tr>

              <td>状态</td>
              <td> <span v-if=" orderDetail.invoice_status==1&& orderDetail.is_invoice_status==0&&orderDetail.isstrange!=2" style="font-size:12px;color:red">(待开发票)</span>

                <span v-else-if=" orderDetail.invoice_status==1&& orderDetail.is_invoice_status==1" style="font-size:12px;color:red;  cursor: pointer;margin-left:10px"
                  @click="dialogFormVisible3=true"> 查看已开发票</span>
                <span v-else>无</span>
              </td>
            </tr>
          </table>
        </el-col>

      </el-row>

    </div>
    <div class="box">
      <h1>配送信息</h1>
      <table>
        <tr>
          <td style="width:100px">收件地址</td>
          <td> {{ orderDetail.addressinfo + " " + (orderDetail.get_address||{}).address }} </td>
        </tr>
        <tr>
          <td>收件人</td>
          <td> {{ (orderDetail.get_address || {}).receiver }}</td>
        </tr>
        <tr>
          <td>电话</td>
          <td> {{ (orderDetail.get_address || {}).tel }}</td>
        </tr>
      </table>
    </div>

    <div class="box">
      <h1>订单信息 <span v-if='orderDetail.out_trade_no'> (主订单：{{orderDetail.out_trade_no}})</span></h1>
      <table class="tab1">

        <template v-if='this.$route.query.order_master_id '>
          <tr class="td1">
            <td style='width:60%'>商品</td>
            <td style="width:30%">价格</td>
            <td style="width:10%">数量</td>
          </tr>
          <tr class="zorder" v-for='(zorder,idx) in orderDetail.zorder.data' :key='idx'>
            <td colspan="8" style="padding:0">

              <table>

                <tbody>

                  <tr>
                    <td colspan="1" style="padding:10px">订单编号：{{ zorder.out_request_no }}</td>

                    <td colspan="2" style="padding:10px">店铺名称：{{ zorder.shop_name }}</td>

                  </tr>

                  <tr v-for="(goods, i) in zorder.get_order_product" :key="i" class="godds">

                    <td style="width:60%">
                      <div class="pro">
                        <img class="item-img" :src="$config.baseUrl + goods.files_path" />
                        <dl>
                          <dt> {{goods.name}}</dt>
                          <dd style="color:#999"> {{ JSON.parse(goods.option || "{}").spec }} </dd>
                        </dl>
                      </div>
                    </td>

                    <td style="width:30%">
                      ￥
                      <!-- <span v-if=" goods.pmax_price">{{ goods.pmax_price }}</span> -->
                      <span v-if=" JSON.parse(goods.option).price">{{ JSON.parse(goods.option).price }}</span>
                      <span v-else>{{ parseFloat(goods.price / 100).toFixed(2) }}</span>
                    </td>
                    <td style="width:10%"> <span class="quantity">x{{ goods.quantity }}</span> </td>

                  </tr>
                  <tr>
                    <td colspan="4" style="padding:10px  ; ">
                      买家留言：<span v-if='zorder.mark_info'>{{ zorder.mark_info }} </span> <span v-else>无</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="4" style="padding-top:0 ;text-align:right">
                      店铺抵用券：<i> <template v-if='zorder.get_shop_coupon'> {{zorder.get_shop_coupon.money}}</template>
                        <template v-else>无</template></i>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="4" style="padding-top:0 ;text-align:right">
                      运 费：<i>{{zorder.shipping_money}}元</i>
                    </td>
                  </tr>

                </tbody>

              </table>
            </td>
          </tr>
        </template>
        <template v-else>

          <tr class="zorder">
            <td colspan="8" style="padding:0">

              <table>

                <tbody>
                  <tr class="td1">
                    <td style='width:60%'>商品</td>

                    <td style="width:30%">价格</td>
                    <td style="width:10%">数量</td>
                  </tr>
                  <tr>
                    <td colspan="1" style="padding:10px">订单编号：{{ orderDetail.out_request_no }}</td>
                    <td colspan="1" style="padding:10px">店铺名：{{ orderDetail.shop_name }}</td>

                  </tr>

                  <tr v-for='(goods,idx) in orderDetail.get_order_product' :key='idx' class="godds">

                    <td style="width:60%">
                      <div class="pro">
                        <img class="item-img" :src="$config.baseUrl + goods.files_path" />
                        <dl>
                          <dt> {{goods.name}}</dt>
                          <dd style="color:#999"> {{ JSON.parse(goods.option || "{}").spec }} </dd>
                        </dl>
                      </div>
                    </td>

                    <!-- <td style="width:20%"> {{goods.shop_name}}</td> -->
                    <td style="width:30%">
                      ￥
                      <!-- <span v-if=" goods.pmax_price">{{ goods.pmax_price }}</span> -->
                      <span v-if=" JSON.parse(goods.option).price">{{ JSON.parse(goods.option).price }}</span>
                      <span v-else>{{ parseFloat(goods.price/100 ).toFixed(2) }}</span>
                    </td>
                    <td style="width:10%"> <span class="quantity">x{{ goods.quantity }}</span> </td>

                  </tr>
                  <tr>
                    <td colspan="4" style="padding:10px  ; ">
                      买家留言：<span v-if='orderDetail.mark_info'>{{ orderDetail.mark_info }} </span> <span v-else>无</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="4" style="padding-top:0 ;text-align:right">
                      店铺抵用券：<i> <template v-if='orderDetail.get_shop_coupon'> {{orderDetail.get_shop_coupon.money}}</template>
                        <template v-else>无</template></i>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="4" style="padding-top:0 ;text-align:right">
                      运 费：<i>{{orderDetail.shipping_money}}元</i>
                    </td>
                  </tr>

                </tbody>

              </table>
            </td>
          </tr>
        </template>
        <tr>
          <td v-if='this.$route.query.order_master_id ' colspan="4" class="td4"> 平台抵用券
            <i> <template v-if='orderDetail.get_coupon'> {{orderDetail.get_coupon.money}}</template><template v-else>无</template> </i>

          </td>

          <td v-else colspan="4" class="td4">
            平台抵用券
            <i> <template v-if='orderDetail.get_coupon'> {{orderDetail.get_coupon.money}}</template><template v-else>无</template> </i>

          </td>

        </tr>
        <tr>
          <td colspan="4" class="td4">
            <span style="margin-right:50px"> 支付方式:{{ orderDetail.payment_method }}</span>

            <span>订单金额：￥<i style="font-size:20px;color: red;font-weight:bold">
                {{((orderDetail.total )/100).toFixed(2)
                      }} </i>

            </span>
          </td>

        </tr>

      </table>

    </div>

    <el-dialog title="是否取消订单" :visible.sync="cancel_box" width="30%">

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel_box = false">取 消</el-button>
        <el-button type="primary" @click="cancel()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="是否删除订单" :visible.sync="del_box" width="30%">

      <span slot="footer" class="dialog-footer">
        <el-button @click="del_box = false">取 消</el-button>
        <el-button type="primary" @click="del()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="已开发票 " :visible.sync="dialogFormVisible3">
      <p style="  text-align: center;"> <img :src=" $config.baseUrl + (this.orderDetail.get_invoice || {}).invoice_link" class="avatar"></p>

    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      value: false,
      cancel_box: false,
      del_box: false,
      show: false,
      info: 1,
      dialogFormVisible3: false,
      province: "",
      city: "",
      area: "",
      street: "",
      coupon: "",

      orderSure: undefined,
      clickData: undefined,
      moreShow: false,
      isVisible: false,
      isVisible1: false,
      showOff: false,
      gallery: [],

      menuItems: [
        // {
        //   name: "加入购物车",
        //   value: 0
        // },
        {
          name: "申请开发票",
          value: 1
        }
      ],
      visible: false,
      popupVisible: false,
      order: [],
      cont: "",
      orderDetail: { get_order_product: [] },

      val: undefined,
      type: ""
    };
  },

  watch: {},
  computed: {

    total() {
      let amt = 0;
      this.orderDetail.get_order_product.forEach(i => {
        amt += parseFloat(i.total);
      });
      return amt / 100;
    }
  },
  mounted() {
    this.getDetail();
    if (this.$route.query.order_master_id) {
      this.type = 1;
    } else {
      this.type = "";
    }
  },
  methods: {
    refund(id, num, str) {
      const routeData = this.$router.resolve({
        path: "/member-order-refund",
        query: { id: id, num: num, str: str }
      });

      window.open(routeData.href, "_blank");
    },

    logistics(item) {
      this.url =
        "https://www.baidu.com/s?wd=" + item.shipping_name + item.shipping_code;

      window.open(this.url);
    },
    toComplain(id) {
      const routeData = this.$router.resolve({ path: "/member-complaint-edit", query: { id: id } });
      window.open(routeData.href, "_blank");
    },
    toAddComplain(id) {
      const routeData = this.$router.resolve({ path: "/member-order-complaint", query: { order_id: id } });
      window.open(routeData.href, "_blank");
    },

    //订单添加
    send(item) {
      let order = item.get_ordernum || item;
      this.$put("home/order/" + order.order_id, {
        payment_type: order.payment_type
      }).then(res => {
        this.$router.push({
          path: "/payType",
          query: {
            order_master_id: order.order_id,
            totalMoney: (order.total / 100).toFixed(2)
          }
        });
      });
    },

    // 再次购买
    toBuy(id) {
      this.$post("/home/buyAgain", {
        order_id: id
      }).then(() => {
        this.$router.push("/cart");
      });
    },
    //取消订单
    cancel() {
      this.$put("home/order/" + this.orderDetail.order_id, {
        isvoid: 1
      }).then(res => {
        console.log(res);
        this.cancel_box = false;

        this.$message.success("已取消订单");
        this.$router.go(-1);
      });
    },
    //删除订单
    del() {
      this.$del("home/order/" + this.orderDetail.order_id, { m_type: 1 }).then(
        () => {
          this.del_box = false;
          this.$message.success("已删除订单");
          this.$router.go(-1);
        }
      );
    },
    wuliu() {
      this.url =
        "https://www.baidu.com/s?wd=" +
        this.orderDetail.shipping_name +
        this.orderDetail.shipping_code;

      window.open(this.url);
    },

    evaluate(id, num) {
      this.$router.push({
        path: "/member-order-evaluate",
        query: { id: id, num: num }
      });
    },
    confirm() {
      this.$confirm("是否确认收货", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$post("home/order/member/confirg", {
          order_id: this.orderSure.order_id
        }).then(res => {
          this.$message.success("已确认收货");
          location.reload();
        });
      });
    },

    //获取订单详情信息
    getDetail() {
      this.$get(
        "home/order/" +
        (this.$route.query.order_master_id
          ? this.$route.query.order_master_id
          : this.$route.query.order_id),
        {
          m_type: this.$route.query.order_master_id ? 1 : ""
        }
      ).then(res => {
        console.log("home/order", res);
        this.orderDetail = res;

        if (this.orderDetail.order_status_id == 3) {
          this.info = 3;
        }
        if (
          this.orderDetail.order_status_id == 13 ||
          this.orderDetail.order_status_id == 18
        ) {
          this.info = 2;
        }
        if (this.orderDetail.order_status_id == 1) {
          this.info = 1;
        }
        if (this.orderDetail.order_status_id == 5) {
          this.info = 4;
        }
        if (this.orderDetail.order_status_id == 6) {
          this.info = 5;
        }
        if (!this.orderDetail.shop_coupon_id) return;
        this.$get("home/shop_coupon/" + this.orderDetail.shop_coupon_id).then(
          res => {
            this.coupon = res.money;
          }
        );
      });
    }
  },
  components: {},
  created() { }
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/mixins";
::v-deep .el-step__title.is-process {
  color: #c0c4cc !important    ;
}
::v-deep .el-step__head.is-process {
  color: #c0c4cc !important;
}
::v-deep .el-step__head.is-process {
  border-color: #c0c4cc !important ;
}

table td {
  border-collapse: collapse;
  border: none !important;
}
table tr {
  border-collapse: collapse;
  border: none;
}
table {
  border-collapse: collapse;
  border: none;
}
.tab1 {
  background: #f5f5f5;
}
.td1 {
  padding: 10px !important;
  background: #eee;
}
.td1 td {
}
.td2 td {
  border-bottom: 1px solid rgba(207, 206, 206, 0.933);
}
.td4 {
  text-align: right;
  span {
  }
}
.zorder {
  background: #f5f5f5;
  border-bottom: 1px solid rgba(207, 206, 206, 0.5);
}
.pro {
  display: flex;
  flex-direction: row;
  dl {
    margin-top: 10px;
    dt {
      margin-bottom: 10px;
    }
  }
  img {
    margin-right: 10px;
    width: 70px;
    height: 70px;
  }
}
.red {
  color: red;
}
table {
  width: 100%;
  td {
    padding: 5px;
  }
}
.zt {
  padding: 10px 0;
  @include space-between;
  p {
    color: red;
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
  }
}
.btn {
  text-align: right;

  li {
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid #eee;
    display: inline-block;
    margin-right: 20px;
    border-radius: 20px;
  }
}
.box {
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 10px;
  background: #fff;
  .emty {
    text-align: center;
    i {
      font-size: 25px;
      margin: 20px auto;
    }
  }

  h1 {
    border-bottom: 1px solid #eee;
    line-height: 40px;
    // margin-bottom: 20px;
  }
}
.tab1 {
  .total {
    text-align: right;
    i {
      margin-left: 50px;
      width: 100px;
      display: inline-block;
      line-height: 30px;
    }
  }

  .tr1 {
    text-align: center;
  }
  .tr2 {
    text-align: center;
    td:nth-child(1) {
      ul {
        text-align: left;
        flex: 1;
      }
      @include space-between;
      img {
        margin-right: 10px;
      }
    }
  }
  background: #fff;
  td {
    padding: 10px;
    border: 1px solid #eee;
  }
  width: 100%;
  border-collapse: collapse;
}
.tab3 td {
  padding: 0;
}
.tab2 {
  padding: 20px;
  margin-top: 10px;
  background: #fff;
  .code {
    p {
      padding: 5px 0;
    }
  }

  width: 100%;
}
</style>
